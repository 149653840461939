$menu-background-color: #1e212a;
$primary-color: #f50a37;
$border-color: #e8e8e8;
$selected-color: #dde5eb;
$content-background-color: #f8f7f7;
$spacer-background-color: #f2f2f2;
$inactive-color: #bdc7d0;
$active-color: #495057;
$active-tab-color: #f50a37;
$inactive-tab-color: #787878;
$inactive-text-color: #787878;
$currency-color: #c8c8c8;
$now-color: #f50a37;
$success-color: #34d1aa;
$warning-color: rgba(255, 229, 170, 0.6);
$error-color: #f50a37;
$success-text-bg: #c2f1e5;
$item-header-background-color: #eef2f5;
$checked-color: #787878;
$alternate-row-color: #f6f8fa;
$alternate-table-row-color: #ffeff2;
$alternate-table-row-color-even: #ffffff;
$alternate-table-row-color-odd: #fbfbfb;
$icon-color: #ffc046;
$notes-background-color: #fafafa;
$circle-color: #ffffff;
$circle-border-first-color: #a3ead9;
$circle-border-second-color: #c5f1ff;
$focus-inner-color: #9bbcf3;
$focus-outer-color: #a6c7ff;
$focus-text-input-color: #eef8ff;
$validation-failed-color: #ffcccc;
$validation-attention-color: #ffe5aa;
$validation-attention-border-color: #909090;
$tab-active: #ffffff;
$tab-inactive: #eef2f5;
$tab-background: #cde6f5;
$validation-background-color: #fff6e2;
$left-menu-width: 250px;
$right-menu-width: 71px;
$top-panel-height: 100px;
$top-tabs-height: 75px;
$case-summery-note: #eef2f5;
$case-summery-history-even-row: #fbfbfb;
$case-summary-history-border-color: #e8e8e8;
$menu-selected-color: #eeeeee;
$case-summery-border-shadow: #0000000a;
$disabled-color: #f9f9f9;
$toogle-switch-background-color: #cbcbcb;
$standard-text: #000000;
$dropdown-shadow: #959595;
$modal-box-shadow: #00000029;
$dropdown-focus-color: #f5faff;
$selected-row-color: #f1fbff;
$field-invalid-value-color: #fdd1da;
$consent-request-red-background: #f41f48;
$consent-request-yellow-background: #ffcd5b;
$upload-container-color: rgba(0, 0, 0, 0.87);
$upload-container-border-color: #b9b9b9;
$upload-container-background-color: #fbfbfb;

/* Calendar variables */
$required-field-color: #ff0000;
$tertiary-color: #ffe4a4;
