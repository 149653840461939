@import 'variables.scss';

.modal-content {
  .modal-header {
    h3 {
      padding-top: 10px;
      margin-bottom: 0px;
    }
    .modal-close {
      align-self: center;

      .cross-close {
        height: 25px;
        width: 25px;
        cursor: pointer;
      }
    }
  }

  .modal-body {
    background-color: #f2f2f2;
    box-shadow: 2px 2px 10px 0px #dee2e6 inset;
    padding: 20px 30px;
  }

  div.mat-button-focus-overlay {
    background-color: transparent !important;
  }

  button:focus {
    outline: none;
  }
}

.modal-button {
  border-radius: 6px;
  margin-left: 15px;
  background-color: $success-color;
  color: white;
  font-size: 14px;
  height: 34px;
  width: 60px;
  border-width: 0;
}

.yes {
  background-color: $now-color;
  right: 0;
  @extend .modal-button;
}

.no {
  background-color: $success-color;
  //  right: 77px;
  @extend .modal-button;
}
