@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'node_modules/@videogular/ngx-videogular/fonts/videogular.css';
@import 'node_modules/flag-icons/css/flag-icons.min.css';
@import 'variables';
@import 'fonts';
@import 'board';
@import 'drop-down';
@import 'modal';
@import 'case-summary';
@import 'snow';

@media print {
  .isPrinting > * {
    display: none;
  }
  .isPrinting app-print-layout {
    display: block;
    background-color: #ffffff;
  }
  div.card {
    border: 0;
    border-radius: 0;
    .card-body {
      padding: 0;
    }
  }
}

html {
  height: 100%;
}

body {
  background-color: $content-background-color;
  font-family: Regular;
  overflow-x: hidden;
  height: 100%;
  font-size: 14px;
}

//.mat-drawer-inner-container {
//  background-image: url(../../content/images/watch-background.png) !important;
//  background-size: 150px !important;
//  background-repeat: no-repeat !important;
//  background-position: bottom !important;
//}

.form-group {
  margin-bottom: 1rem;
}

input,
textarea {
  width: 100%;
  border: 1px solid $border-color;
  padding-top: 5px;
  padding-left: 7px;
  border-radius: 2px;
}

input {
  height: 34px;
}

mat-checkbox {
  label {
    margin-bottom: 0;
  }
}

//.mat-checkbox .mat-checkbox-frame {
//  border-color: black !important;
//}

.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #f50a37 !important;
}

.required {
  margin-left: 2px;
  color: $required-field-color;
}

button.primary {
  color: #ffffff !important;
  background-color: $primary-color;
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 14px;
  font-weight: 500;
}

button.draft {
  border: 2px solid $primary-color !important;
  color: $primary-color;
  font-weight: 700;
}

button.mat-datepicker-close-button {
  visibility: hidden !important;
}

button[disabled][disabled],
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  color: #0a0a0a !important;
  border-color: #cecece !important;
  background-image: linear-gradient(#eaeaea, #eeeeee 60%, #cecece);
  background-repeat: no-repeat;
}

div.ng-input > input[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

td.text-align-center {
  text-align: center;
}

.text-inactive {
  color: $inactive-color;
}

.text-deleted {
  color: $primary-color;
}

.popover .popover-header {
  background-color: $primary-color;
  color: white;
  font-weight: 500;
}

.force-cursor {
  cursor: pointer;
}

/* Remove ngb alert close button (×) */
ngb-alert.remove-close-button {
  button.close {
    display: none !important;
  }
}

/* Adjust value and arrow vertical alignment in untouched state */
.ng-select .ng-select-container .ng-value-container {
  padding-top: 4px;
}
.ng-select .ng-arrow-wrapper {
  padding-top: 4px;
}

/* Adjust value and arrow vertical alignment in touched state */
.ng-select-bottom .ng-select-container .ng-value-container {
  padding-top: 15px;
}
.ng-select-bottom .ng-arrow-wrapper {
  padding-top: 15px;
}

/* Make date time picker show in modals */
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 2001 !important;
}

/* Make google.maps.places.Autocomplete show in modals */
.pac-container {
  z-index: 1501 !important;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow-x: hidden !important;
}

.mat-dialog-content {
  max-height: 1000px !important;
}

/* For input-date.component.ts */
.input .position-wrapper .mat-icon-button {
  position: absolute !important;
  width: 0;
  height: 0;
}

button.mat-button-base:focus {
  outline: 0;
}

button.mat-icon-button.mat-button-base {
  .mat-button-wrapper {
    width: 0;
  }

  .mat-button-focus-overlay {
    display: none;
  }
}

.spinner-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.2;
  z-index: 9999;

  .spinner-border {
    position: fixed;
    z-index: 9999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
  }
}

.case-summary-email-fax-dialog {
  width: 1600px;
}
@media only screen and (max-width: 1600px) {
  .case-summary-email-fax-dialog {
    width: 80vw;
  }
}
